import config from "../config";

const create = (name, value, days) => {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = `; expires=  ${date.toGMTString()}`;
  } else var expires = "";
  document.cookie = `${name} = ${value}; ${expires} ${config.cookieConfig}`;
};

const erase = (name) => {
  create(name, "", -1);
};

const read = (name) => {
  const nameEQ = `${name}=`;

  const cookieArray = document.cookie.split(";");
  let result;
  cookieArray.map((item) => {
    item = item.trim();
    if (item.indexOf(nameEQ) === 0) {
      result = item.substring(nameEQ.length, item.length);
    }
  });
  return result;
};

export default {
  create,
  erase,
  read,
};
