import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import { Reveal } from "react-reveal";

import Button from "../../../../../../components/Button/Button";
import Alert from "react-bootstrap/Alert";
import LoginInput from "../../../../../../components/AuthForm";
import AuthButton from "../../../../../../components/AuthButton";
import SelectInput from "../../../../../../components/AuthForm/SelectInput";
import {
  getCities,
  getCounties,
  submitRegister,
} from "../../../../../../AppRequests";
import Checkbox from "../../../../../../components/AuthForm/Checkbox";
import moment from "moment";
import RadioGroup from "../../../../../../components/AuthForm/RadioGroup";
import RegisterCompleted from "../Completed/RegisterCompleted";
import { TagEvents, TagManager } from "../../../../../../utils/GTM";
import Datepicker from "../../../../../../components/AuthForm/Datepicker";
import Loader from "../../../../../../components/Loader";

import ReeValidate from "ree-validate";

const Register = ({ goToLogin }) => {
  const [hasRegistered, setHasRegistered] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    birthday: null,
    gender: "",
    state: "",
    city: "",
    marketingOptin: false,
    tandc: false,
  });

  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);

  // Validator
  const validator = new ReeValidate({
    first_name: "required",
    last_name: "required",
    email: "required|email",
    phone: "required|numeric|max:10",
    birthday: "required",
    gender: "required",
    state: "required",
    city: "required",
    tandc: "required",
  });

  const [validatorErrors, setValidatorErrors] = useState(validator.errors);

  const handleCounties = async () => {
    const { data } = await getCounties();
    setCounties(data);
  };

  const handleCities = async () => {
    const state = counties.find(({ countyAbbreviation }) => countyAbbreviation === user.state);
    const abbreviation = state.countyAbbreviation;
    if (abbreviation == "B") {
      const sectors = [
        { name: "Sector 1" },
        { name: "Sector 2" },
        { name: "Sector 3" },
        { name: "Sector 4" },
        { name: "Sector 5" },
        { name: "Sector 6" },
      ];
      setCities(sectors);
    } else {
      const { data } = await getCities(abbreviation);
      setCities(data);
    }
  };

  const handleSubmit = async () => {
    const isSector = user.city.includes("Sector");
    const userData = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      state: user.state,
      city: isSector ? user.city[user.city.length - 1] : user.city,
      gender: user.gender.toLowerCase(),
      birthday: moment(user.birthday).format("DD/MM/YYYY"),
      las_preference: user.marketingOptin,
      tandc: user.tandc,
    };

    setLoading(true);
    const response = await submitRegister(userData);
    if (response.status === "success") {
      setHasRegistered(true);
      TagManager.dataLayer(TagEvents.finishRegistration);
    } else {
      const { errors } = response;
      errors.forEach(({ context, message }) => {
        validator.errors.add({
          field: context.key,
          msg: message,
        });
      });
      setValidatorErrors(validator.errors);
    }
    setLoading(false);
  };

  const handleChange = (key) => (e) => {
    switch (key) {
      case "gender":
        setUser({ ...user, [key]: e.target.value });
        break;
      case "birthday":
        setUser({ ...user, [key]: e });
        break;
      case "tandc":
        const checked = e.target.checked;
        setUser({ ...user, [key]: checked });
        break;
      case "marketingOptin":
        const check = e.target.checked;
        setUser({ ...user, [key]: check });
        break;
      default:
        setUser({ ...user, [key]: e.target.value });
    }
    validatorErrors.remove(key);
  };

  useEffect(() => {
    handleCounties();
  }, []);
  useEffect(() => {
    if (user.state) {
      handleCities();
    }
  }, [user.state]);

  if (loading) return <Loader />;

  const stateChoices = counties.map(function ({ county, countyAbbreviation }) {
    return {id: countyAbbreviation, name: county};
  });

  const cityChoices = cities.map(function ({ name }) {
    return {id: name, name: name};
  });

  return (
    <div className={styles.container}>
      {hasRegistered ? (
        <RegisterCompleted />
      ) : (
        <Reveal effect="fadeInUp">
          <h1>Creează-ți un cont</h1>
          <form noValidate="novalidate" className={styles.form}>
            <LoginInput
              info={validatorErrors.first("first_name")}
              onChange={handleChange("first_name")}
              name="first_name"
              label="Prenume"
              value={user.first_name}
              type="text"
              first
            />
            <LoginInput
              info={validatorErrors.first("last_name")}
              onChange={handleChange("last_name")}
              name="last_name"
              label="Nume"
              value={user.last_name}
              type="text"
              first
            />
            <LoginInput
              info={validatorErrors.first("email")}
              onChange={handleChange("email")}
              name="email"
              value={user.email}
              label="Adresa de e-mail"
              type="text"
              first
            />
            <LoginInput
              info={validatorErrors.first("phone")}
              onChange={handleChange("phone")}
              name="phone"
              value={user.phone}
              label="Telefon"
              type="text"
              first
            />
            <Datepicker
              info={validatorErrors.first("birthday")}
              onChange={handleChange("birthday")}
              label="Data nașterii"
              name="birthday"
              value={user.birthday}
            />
            <SelectInput
              onChange={handleChange("state")}
              value={user.state}
              name="state"
              label="Județ"
              arr={stateChoices}
              first
              info={validatorErrors.first("state")}
            />
            <SelectInput
              onChange={handleChange("city")}
              value={user.city}
              name="city"
              label="Oraș"
              disabled={!user.state}
              arr={cityChoices}
              first
              info={validatorErrors.first("city")}
            />
            <RadioGroup
              arr={["M", "F"]}
              value={user.gender}
              onChange={handleChange("gender")}
              text="Sex"
              info={validatorErrors.first("gender")}
            />
            <Checkbox
              isFirst
              checked={user.marketingOptin}
              onChange={handleChange("marketingOptin")}
              text="Nu vreau să ratez ofertele (reduceri, ediții limitate, noutăți despre produse ș.a.) Philip Morris Products SA. și sunt de acord să primesc prin email sau SMS informații comerciale despre IQOS, HEETS sau alte produse/servicii conexe oferite de Philip Morris Products SA. sau de partenerii săi."
            />
            <Checkbox
              checked={user.tandc}
              onChange={handleChange("tandc")}
              text="*Am citit și înțeles Notificarea de protecție a datelor."
              href="https://www.iqos.ro/privacy"
              info={validatorErrors.first("tandc")}
            />
          </form>

          <br />
          <AuthButton type={"primary"} noLink onClick={handleSubmit}>
            Înregistrează-te
          </AuthButton>
          <Button type="goBack" noLink onClick={goToLogin}>
            Înapoi
          </Button>
        </Reveal>
      )}
    </div>
  );
};
export default Register;
