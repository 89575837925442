import React, { useContext, useState, useEffect } from "react";
import styles from "./Style.module.scss";
import iqos3_1 from "./assets/starter-kit-iqos-3-duo-1.png";
import iqos3_2 from "./assets/starter-kit-iqos-3-duo-2.png";
import iqos3_3 from "./assets/starter-kit-iqos-3-duo-3.png";
import iqos3_4 from "./assets/starter-kit-iqos-3-duo-4.png";
import iqos3_5 from "./assets/starter-kit-iqos-3-duo-5.png";
import iqos24_1 from "./assets/starter-kit-iqos-2.4-1.png";
import iqos24_2 from "./assets/starter-kit-iqos-2.4-2.png";
import { Reveal } from "react-reveal";

import { AppContext } from "../../../../AppContext";

const ListProducts = () => {
  const [price, setPrice] = useState({ priceFirst: 449, priceSecond: 149 });
  const [iqos3Color, setIqos3Color] = useState(0);
  const [iqos24Color, setIqos24Color] = useState(0);
  const { discount } = useContext(AppContext);
  const products = [
    {
      title: "Starter / Loyalty Kit IQOS 3 Duo",
      disclaimer:
        "",
      colors: ["#F3F2F3", "#DDCAB4", "#4A70A8", "#984438", "#5A6066"],
      images: [iqos3_1, iqos3_2, iqos3_3, iqos3_4, iqos3_5],
      price: price.priceFirst,
      discountedPrice: 359,
    },
    {
      title: "Starter / Loyalty Kit IQOS 2.4+",
      disclaimer:
        "",
      colors: ["#E4E6E6", "#263235"],
      images: [iqos24_1, iqos24_2],
      price: price.priceSecond,
      discountedPrice: 119,
    },
  ];
  useEffect(() => {
    const calcDiscount = (price) =>
      Math.floor(price - (price / 100) * discount);

    discount !== 0 &&
      setPrice({
        priceFirst: calcDiscount(449),
        priceSecond: calcDiscount(149),
      });
  }, [discount]);
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.listProducts}>

          <div className={styles.product}>
            <Reveal effect={"fadeInUp"}>
              <img src={products[0].images[iqos3Color]} alt={products[0].title} />
              <h3 dangerouslySetInnerHTML={{ __html: products[0].title }} />

              <div className={styles.colors}>
                {products[0].colors.map((color, key) => (
                  <div
                    style={{ backgroundColor: color }}
                    className={key === iqos3Color ? styles.active : ""}
                    key={key}
                    onClick={() => setIqos3Color(key)}
                  />
                ))}
              </div>
              <p dangerouslySetInnerHTML={{ __html: products[0].disclaimer }} />
            </Reveal>
          </div>

          <div className={styles.product}>
            <Reveal effect={"fadeInUp"}>
              <img src={products[1].images[iqos24Color]} alt={products[1].title} />
              <h3 dangerouslySetInnerHTML={{ __html: products[1].title }} />

              <div className={styles.colors}>
                {products[1].colors.map((color, key) => (
                  <div
                    style={{ backgroundColor: color }}
                    className={key === iqos24Color ? styles.active : ""}
                    key={key}
                    onClick={() => setIqos24Color(key)}
                  />
                ))}
              </div>
              <p dangerouslySetInnerHTML={{ __html: products[1].disclaimer }} />
            </Reveal>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ListProducts;
