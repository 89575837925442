import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    sendForgotPasswordRequest: ['email'],
    forgotPasswordError: ['email'],
    forgotPasswordLoading: null,
    forgotPasswordSuccess: null
});

export const ForgotPasswordTypes = Types;
export default Creators;
