import React from "react";

// Components
import HowTo from "./Sections/HowTo";
import Layout from "../../components/Layout";
import HowItWorks from "./Sections/HowItWorks";
import ListBenefits from "./Sections/ListBenefits";
import ListProducts from "./Sections/ListProducts";
import QuizWrapper from "./Sections/QuizWrapper/QuizWrapper";
import EuropeanRestrictions from "./Sections/EuropeanRestrictions";
import CookieDisclaimer from "./Sections/CookieDisclaimer/CookieDisclaimer";

const Home = () => (
  <Layout>
    <QuizWrapper />
    <ListProducts />
    <HowTo />
    <HowItWorks />
    <ListBenefits />
    <EuropeanRestrictions />
    <CookieDisclaimer />
  </Layout>
);

export default Home;
