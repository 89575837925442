import React, { createContext, useEffect, useState } from "react";
import { TagEvents, TagManager } from "./utils/GTM";
import { getStatus } from "./AppRequests";
import cookies from "./utils/cookies";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [startedQuiz, setStartedQuiz] = useState(false);
  const [finishedQuiz, setFinishedQuiz] = useState(false);
  const [authenticatedQuiz, setAuthenticatedQuiz] = useState(false);

  useEffect(() => {
    const displayStatus = async () => {
      setLoading(true);
      const cookieDcsId = cookies.read('consumer_dcs_id');
      const dcsId = parseInt(cookieDcsId) !== 0 ? cookieDcsId : null;
      const res = await getStatus(dcsId);

      setStartedQuiz(res.data.startedQuiz);
      setFinishedQuiz(res.data.finishedQuiz);
      setAuthenticatedQuiz(res.data.authenticatedQuiz);
      setLoading(false);
    };
    displayStatus();
  }, []);

  const [questions, setQuestions] = useState([]);
  const [results, setResults] = useState({});
  const [discount, setDiscount] = useState(0);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  //isAuth will set to true when u're on Success page and wanna go to login section
  const [isAuth, setIsAuth] = useState(false);

  const handleStartQuiz = (buttonPosition) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setStartedQuiz(true);

    if (!startedQuiz && !finishedQuiz) {
      // Track when button is in 'Start quizz' mode
      TagManager.dataLayer(TagEvents.startQuiz(buttonPosition));

      // Virtual pageview track for first question
      TagManager.dataLayer(TagEvents.virtualPageView(1));
    }

    // Track when button is in 'Buy now' mode
    if (startedQuiz && finishedQuiz) {
      TagManager.dataLayer(TagEvents.orderNow(buttonPosition));
      setIsAuth(true);
    }
  };
  const ctaButton = () => {
    if (!startedQuiz && !finishedQuiz) return "Start quiz";
    if (startedQuiz && !finishedQuiz) return "Continuă quiz";
    if (startedQuiz && finishedQuiz) return "Cumpără acum";
  };

  const defaultValues = {
    startedQuiz,
    setStartedQuiz,
    finishedQuiz,
    setFinishedQuiz,
    questions,
    setQuestions,
    results,
    setResults,
    discount,
    setDiscount,
    handleStartQuiz,
    step,
    setStep,
    ctaButton,
    authenticatedQuiz,
    loading,
    setLoading,
    loadingQuestions,
    setLoadingQuestions,
    isAuth,
    setIsAuth,
  };

  return (
    <AppContext.Provider value={defaultValues}>{children}</AppContext.Provider>
  );
};
