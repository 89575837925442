import React, {useContext} from "react";
import styles from "./Style.module.scss";
import successStyles from "../Success/Style.module.scss";
import { Reveal } from "react-reveal";
import {AppContext} from "../../../../../../AppContext";
import Button from "../../../../../../components/Button/Button";
import config from "../../../../../../config";

const RegisterCompleted = () => {
  const { discount } = useContext(
    AppContext
  );

  const goToRegulation = () => {
    window.open(config.path.regulation, '_blank');
  }

  return (
    <Reveal effect="fadeInUp">
      <h1>Te-ai înregistrat cu succes!</h1>
      <p>
        Vei primi un link de activare al contului pe e-mail.
        <br />
        Verifică-ți e-mailul pentru confirmarea contului.
      </p>

      <p>După activarea contului, accesează secțiunea "<span>SHOP</span>" din meniul de sus al paginii și poți achiziționa un <b>Starter Kit IQOS 3 DUO</b> sau un <b>Starter Kit IQOS 2.4+</b> cu discount-ul câștigat deja la quiz.</p>

      <span className={successStyles.currentDiscount}>
        <b>
          {discount}
          <div className={styles.icon}>%</div>
        </b>
        <small>reducere</small>
      </span>

      <Button type={"secondary"} noLink onClick={goToRegulation}>
        Regulamentul campaniei
      </Button>
    </Reveal>
  );
};

export default RegisterCompleted;
