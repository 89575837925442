import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ForgotPasswordActions, { ForgotPasswordTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';

export function* forgotPassword({email}) {
    yield put(ForgotPasswordActions.forgotPasswordLoading());
    try {
        const response = yield call(() => axios.post('/forgot-password', { email }));
        yield put(ForgotPasswordActions.forgotPasswordSuccess(response));
    } catch (error) {
        yield put(ForgotPasswordActions.forgotPasswordError(error.response.data));
    }
}

export default function* ForgotPasswordSaga() {
    yield takeLatest(ForgotPasswordTypes.SEND_FORGOT_PASSWORD_REQUEST, forgotPassword);
}
