import React from "react";
import { connect } from "react-redux";
import ForgotPasswordActions from "../../../stores/ForgotPassword/Actions";
import { Switch, Link } from "react-router-dom";

// Assets
import Logo from "../../../assets/images/ui/logo/logo_big.png";
import styles from "./ForgotPassword.module.scss";

// Sections
import LoginInput from "../../../components/AuthForm";
import AuthButton from "../../../components/AuthButton";

import { LinkContainer } from "react-router-bootstrap";
import { Nav, Image } from "react-bootstrap";
import config from "../../../config";
import ReeValidate from "ree-validate";
import classnames from "classnames";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    let dictionary = {
      en: {
        messages: {
          required: () => "Acest câmp este obligatoriu.",
          email: () => "Te rugăm să introduci o adresă de email validă.",
        },
      },
    };

    this.validator = new ReeValidate({
      email: "required|email",
    });

    this.validator.localize(dictionary);
    this.state = {
      email: "",
      errors: this.validator.errors,
    };
  }

  handleChange = (field, value) => {
    const { errors } = this.validator;
    errors.remove(field);

    this.setState((state) => ({
      ...state,
      email: value,
    }));

    this.validator.validate(field, value).then(() => {
      this.setState({ errors });
    });
  };

  handleSubmit = () => {
    const { email } = this.state;
    this.props.submit(email);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleError = (error) => {
    if (error) {
      const emailError = error.email ? error.email : undefined;
      const passwordError = error.password ? error.password : undefined;
      const generalError = error ? error : undefined;

      return emailError
        ? emailError
        : passwordError
        ? passwordError
        : generalError
        ? generalError
        : undefined;
    }
  };

  render() {
    return (
      <div className={styles.login}>
        <div className={styles.loginContainer}>
          <div className={styles.closePp}></div>

          <div className="center-align">
            <div className="loginWrapper">
              <Image src={Logo} className={styles.logo} /> <br />
              {this.props.isSuccess ? (
                <>
                  <p>Parola ta a fost resetată cu succes</p>
                  <p>Urmează instrucțiunile primite pe emailul tău.</p>
                </>
              ) : (
                <p>
                  Introdu adresa de email și în scurt timp vei primi
                  instrucțiunile pentru resetarea parolei.
                </p>
              )}
              <br />
              <form>
                <label
                  className={classnames({
                    "has-error": this.state.errors.has("email"),
                  })}
                >
                  <input
                    type="text"
                    placeholder="* Adresa de e-mail"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={(e) =>
                      this.handleChange("email", e.target ? e.target.value : e)
                    }
                  />

                  {this.state.errors.has("email") && (
                    <span className="error">
                      {this.state.errors.first("email")}
                    </span>
                  )}
                </label>

                <AuthButton
                  onClick={this.handleSubmit}
                  loading={this.props.loading}
                >
                  Recuperare parolă
                </AuthButton>

                <br />

                <LinkContainer to={config.path.login} className="go_back">
                  <Nav.Link eventKey="">Înapoi</Nav.Link>
                </LinkContainer>
                <a
                  className={styles.register}
                  href="https://www.iqos.ro/profiles-add"
                  target={"_blank"}
                >
                  Înregistrează-te pe <b>IQOS.RO</b>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.forgotPassword.loading,
  isSuccess: state.forgotPassword.isSuccess,
  error: state.forgotPassword.errors,
});

const mapDispatchToProps = {
  submit: (email) => ForgotPasswordActions.sendForgotPasswordRequest(email),
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
