import React, { useContext } from "react";
import config from "../../../../config";
import styles from "./Styles.module.scss";
import Button from "../../../../components/Button/Button";
import { Reveal } from "react-reveal";
import { AppContext } from "../../../../AppContext";

const EuropeanRestrictions = () => {
  const { handleStartQuiz, ctaButton, authenticatedQuiz } = useContext(
    AppContext
  );

  return (
    <div className={styles.europeanRestrictions}>
      <div className={styles.container}>
        <Reveal effect="fadeInUp">
          <h3>
            Ce presupune interdicția UE privind aroma de mentol în țigarete?
          </h3>
          <p>
            Începând cu <b>15 iunie 2020</b>, toate țigările mentolate și cele
            cu capsulă cu mentol vor fi interzise pe toate piețele UE, inclusiv
            la punctele de vânzare duty free. Această interdicție rezultă din
            Directiva UE privind produsele din tutun (Tobacco Products Directive
            - TPD), transpusă în România prin legea 201/2016. Directiva include
            o interdicție privind țigările și tutunul pentru rulat cu arome
            caracteristice, respectiv produsele cu miros sau gust sesizabil
            diferite decât tutunul. Aceasta înseamnă că{" "}
            <b>țigările mentolate vor dispărea începând cu 15 iunie 2020</b>.
            Aceste prevederi acoperă țigările și tutunul pentru rulat.
          </p>

          <h4>
            HEETS mentolate sunt disponible în continuare, după 15 iunie 2020.
          </h4>

          <Button
            type="primary"
            noLink
            style={authenticatedQuiz ? { display: "none" } : {}}
            onClick={() => handleStartQuiz(4)}
          >
            {ctaButton()}
          </Button>
        </Reveal>
      </div>
    </div>
  );
};

export default EuropeanRestrictions;
