import React from "react";
import store from "store";
import config from "../../../config";
import { connect } from "react-redux";
import LoginActions from "../../../stores/Login/Actions";

import { LinkContainer } from "react-router-bootstrap";
import { Nav, Image } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

// Assets
import EyeIcon from "../../../assets/images/icons/material-eye.svg";
import Logo from "../../../assets/images/ui/logo/logo_big.png";
import Close from "@material-ui/icons/Close";
import styles from "./Login.module.scss";

// Sections
import LoginInput from "../../../components/AuthForm";
import AuthButton from "../../../components/AuthButton";

class Login extends React.Component {
  state = {
    email: undefined,
    password: undefined,
    isLoggedIn: store.get(config.user.token),
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value ? e.target.value : e.target.checked,
    });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    this.props.submit(email, password);
  };

  handleError = (error) => {
    if (error) {
      const emailError = error.errors.email ? error.errors.email[0] : undefined;
      const passwordError = error.errors.password
        ? error.errors.password[0]
        : undefined;
      const generalError = error ? error : undefined;

      return emailError
        ? emailError
        : passwordError
        ? passwordError
        : generalError
        ? generalError
        : undefined;
    }
  };

  render() {
    return (
      <div className={styles.login}>
        <div className={styles.loginContainer}>
          <LinkContainer to={config.path.index} className={styles.closePp}>
            <Nav.Link eventKey="">
              <Close />
            </Nav.Link>
          </LinkContainer>

          <div className="center-align">
            <div className="loginWrapper">
              <Image src={Logo} className={styles.logo} /> <br />
              <h3>
                Intră în cont, <u>folosind datele din IQOS.ro.</u>
              </h3>
              <form>
                {this.props.error ? (
                  <Alert variant="danger">
                    <div>{this.handleError(this.props.error)}</div>
                  </Alert>
                ) : (
                  ""
                )}

                <LoginInput
                  onChange={this.handleChange}
                  name="email"
                  label="Adresa de e-mail"
                  type="email"
                  first
                />

                <LoginInput
                  onChange={this.handleChange}
                  label="Parola"
                  name="password"
                  type="password"
                  secondaryIcon={EyeIcon}
                  last
                />

                <AuthButton
                  onClick={this.handleSubmit}
                  loading={this.props.loading}
                >
                  Conectează-te
                </AuthButton>

                <br />

                <LinkContainer
                  to={config.path.forgotPassword}
                  className={styles.forgotPassword}
                >
                  <Nav.Link eventKey="">Ai uitat parola?</Nav.Link>
                </LinkContainer>

                <a
                  className={styles.register}
                  href="https://www.iqos.ro/profiles-add"
                  target={"_blank"}
                >
                  Înregistrează-te pe <b>IQOS.RO</b>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.login.loading,
  error: state.login.errors,
});

const mapDispatchToProps = {
  submit: (email, password) => LoginActions.login(email, password),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
