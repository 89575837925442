import React from "react";
import {
  Router as BrowserRouter,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import { history } from "../../stores/createStore";
import config from "../../config";
import store from "store";

//Scroll
import ScrollToTop from "./ScrollToTop";

// Auth
import AgeGate from "../../containers/AgeGate";
import Login from "../../containers/Auth/Login";
import Register from "../../containers/Auth/Register";
import ForgotPassword from "../../containers/Auth/ForgotPassword";

// Referral
import Home from "../../containers/Landing";
import Regulation from "../../containers/Regulation";

const isAdult = store.get(config.user.isAdult);

const Router = () => (
  <BrowserRouter history={history} basename={config.proxyPassPath}>
    <ScrollToTop />
    <Switch>
      {window.location.hostname === 'campanie-iqos-quiz.gd.ro' ? null : <Redirect exact from="/" to={config.path.index} />}
      <Route
        exact
        path={config.path.index}
        component={isAdult ? Home : AgeGate}
      />
      <Route
        exact
        path={config.path.regulation}
        component={isAdult ? Regulation : AgeGate}
      />

      <Route exact path={config.path.login} component={Login} />
      <Route exact path={config.path.register} component={Register} />
      <Route
        exact
        path={config.path.forgotPassword}
        component={ForgotPassword}
      />

      <Redirect to={config.path.notFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
