import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './AuthButton.module.scss';
import store from 'store';

const AuthButton = (props) => (
    <button type="submit" className={store.get('isPWA') ? styles.buttonPWA : styles.button} onClick={props.onClick} disabled={props.disabled}>
        {props.loading ? (
            <Spinner as="span" animation="border" variant="white" size="sm" role="loading" aria-hidden="true" />
        ) : (
            props.children
        )}
    </button>
);

export default AuthButton;
