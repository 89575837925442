import { call, put, takeLatest, delay } from 'redux-saga/effects';
import LogoutActions, { LogoutTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';
import config from '../../config';
import { push, go } from 'connected-react-router';

export function* logout({}) {

    yield setAxiosToken(null);

    yield store.remove(config.user.tokenExpireDate);
    yield store.remove(config.user.token);
    yield store.remove(config.user.refreshToken);
    yield store.remove(config.user.expiresIn);
    yield store.remove(config.user.data);
    yield store.remove(config.auth.refreshInProgress);
    yield store.remove(config.user.pushNotificationsToken);

    yield put(LogoutActions.logoutSuccess());
    yield put(go(config.path.index));
}

export default function* logoutSaga() {
    yield takeLatest(LogoutTypes.LOGOUT, logout);
}
