import { createReducer } from 'reduxsauce';
import { LogoutTypes } from './Actions';

const INITIAL_STATE = {
    loading: false,
    errors: null
};

export const logoutLoading = (state) => ({
    ...state,
    loading: true,
    errors: null
});

export const logoutSuccess = (state) => ({
    ...state,
    loading: false,
    errors: null
});

export const reducer = createReducer(INITIAL_STATE, {
    [LogoutTypes.LOGOUT_LOADING]: logoutLoading,
    [LogoutTypes.LOGOUT_SUCCESS]: logoutSuccess
});
