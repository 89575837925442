import { call, put, takeLatest } from 'redux-saga/effects';
import LoginActions, { LoginTypes } from './Actions';
import axios, { setAxiosToken } from '../../utils/axios';
import store from 'store';
import config from '../../config';
import { push } from 'connected-react-router';
import moment from 'moment';
import {TagEvents, TagManager} from "../../utils/GTM";

export function* login({ email, password }) {
    yield put(LoginActions.loginLoading());

    try {
        const response = yield call(() => axios.post('/login', { email, password, isPwa: store.get('isPWA')}));

        const user = response.data; //TO DO remove this, because only token is received
        const token = response.data.token;
        const expiresIn = response.data.expires_in;
        yield setAxiosToken(token);
        yield store.set(config.user.token, token);
        yield store.set(config.user.expiresIn, expiresIn);
        yield store.set(config.user.tokenExpireDate, (new moment()).add(expiresIn, 'seconds'));
        yield store.set(config.user.data, user);

        yield TagManager.dataLayer(TagEvents.loginSuccess);

        yield put(LoginActions.loginSuccess(user));
        yield put(push(config.path.index));
        window.location.reload()
    } catch (error) {
        yield put(LoginActions.loginFail(error.response.data));
    }
}

export default function* loginSaga() {
    yield takeLatest(LoginTypes.LOGIN, login);
}
