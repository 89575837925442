import styled from "styled-components";
import { Checkbox } from "antd";

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 0;
  }

  .ant-checkbox {
    align-self: start;
    margin: 10px;
  }
  .ant-checkbox + span {
    text-align: justify;
    margin-left: 20px;
  }
  span .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d6a76f;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #d6a76f;
    border-color: #d6a76f;
  }
  .ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #d6a76f;
    content: "";
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
`;
