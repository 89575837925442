import React from "react";
import LogoutActions from "../../../stores/Logout/Actions";
import config from "../../../config";
import { connect } from "react-redux";

class MenuList extends React.Component {
  render() {
    const links = [
      { link: config.path.whatIsIQOS, title: "Ce este IQOS?" },
      { link: config.path.tryIQOS, title: "Încearcă" },
      { link: config.path.shop, title: "SHOP" },
      { link: config.path.support, title: "Asistență" },
      { link: config.path.mentholBan, title: "Alege fresh" },
      { link: config.path.community, title: "Comunitate" },
      { link: config.path.iqosClub, title: "IQOS CLUB" },
    ];

    return (
      <>
        {links.map(({ link, title }) => (
          <li key={title}>
            <a href={link}>{title}</a>
          </li>
        ))}
      </>
    );
  }
}

const mapDispatchToProps = {
  logout: () => LogoutActions.logout(),
};

export default connect(null, mapDispatchToProps)(MenuList);
