import React, {useContext} from "react";
import styles from "./Style.module.scss";
import Button from "../../../../../../components/Button/Button";
import {Reveal} from "react-reveal";
import {AppContext} from "../../../../../../AppContext";
import config from "../../../../../../config";
import {TagEvents, TagManager} from "../../../../../../utils/GTM";
import Loader from "../../../../../../components/Loader";

const goToRegulation = () => {
  TagManager.dataLayer(TagEvents.howItWorks);
  window.open(config.path.regulation, '_blank');
}


const Intro = () => {
  const { handleStartQuiz, loading } = useContext(AppContext);
  if (loading) return <Loader />;
  return (
    <div className={styles.introQuiz}>
      <Reveal effect="fadeInUp">
        <h1>
          Răspunde la <b>3 întrebări</b> și obții până la
          <span>
            <b>
              20 <div className={styles.icon}>%</div>
            </b>
            <small>reducere</small>
          </span>
          la achiziția unui dispozitiv IQOS!
        </h1>

        <Button type={"primary"} noLink onClick={() => handleStartQuiz(1)}>
          Start quiz
        </Button>

        <Button type={"secondary"} noLink onClick={goToRegulation}>
          Cum funcționează campania?
        </Button>
      </Reveal>
    </div>
  );
};

export default Intro;
