import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    logout: null,
    logoutLoading: null,
    logoutSuccess: null
});

export const LogoutTypes = Types;
export default Creators;
