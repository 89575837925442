import React from 'react';
import store from 'store';
import classnames from 'classnames';
import {connect} from 'react-redux';
// Assets
import config from '../../../../config';
// Images
// Saga
import LogoutActions from '../../../../stores/Logout/Actions';

class UserDetails extends React.Component {

  render() {
    const { onClick } = this.props;
    return (
      <>
        <div className="account_details" onClick={onClick}>
          <div className={classnames('user_details')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
              <path className="path path-light"
                    d="M13.62,12.92a6.25,6.25,0,1,0-5.24,0A8.5,8.5,0,0,0,2.5,21h1a7.5,7.5,0,0,1,15,0h1A8.5,8.5,0,0,0,13.62,12.92ZM5.75,7.25A5.25,5.25,0,1,1,11,12.5,5.25,5.25,0,0,1,5.75,7.25Z"></path>
            </svg>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logout: () => LogoutActions.logout()
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);
