import React, {createRef, useContext} from 'react';
import store from 'store';
import classnames from 'classnames';

// Assets
import config from '../../config';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

// Sections
import Menu from './parts/Menu';
import Logo from './parts/Logo';
import {connect} from "react-redux";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import UserDetails from "./parts/UserDetails/UserDetails";

import styles from './Header.module.scss';
import authStyles from '../../containers/Landing/Sections/QuizWrapper/components/Auth/Style.module.scss';
import LoginForm from "../../containers/Landing/Sections/QuizWrapper/components/Auth/LoginForm";
import {AppContext} from "../../AppContext";
import cookies from "../../utils/cookies";

class Header extends React.Component {
    state = {
        isLoggedIn: store.get(config.user.token),
        prevScrollpos: window.pageYOffset,
        visible: true,
        loginOverlay: false
    };

    static contextType = AppContext;

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        document.addEventListener('click', this.handleClickOutside, true);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        // const domNode = this.overlay.current;
        //
        // if (!domNode || !domNode.contains(event.target)) {
        //     this.setState({
        //         loginOverlay: false
        //     });
        // }
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;
    };

    toggleModal = () => {
        const body = document.querySelector('body');
        this.props.isVisible === false ? disableBodyScroll(body) : enableBodyScroll(body);
    };

    toggleLoginOverlay = () => {
        const cookieDcsId = cookies.read('consumer_dcs_id');
        const dcsId = parseInt(cookieDcsId) !== 0 ? cookieDcsId : null;
        if (dcsId) {
            window.location = config.path.myIqos;
        } else {
            const {loginOverlay} = this.state;
            this.setState({
                loginOverlay: !loginOverlay
            });
        }
    }

    overlay = createRef();

    render() {
        const { isLoggedIn, visible, loginOverlay } = this.state;

        return (
            <>
                <div
                    className={classnames('header', {
                        'header--hidden': !visible
                    })}
                >
                    <Logo />
                    <Menu />
                    <UserDetails onClick={this.toggleLoginOverlay} />
                </div>

                <div className={classnames(styles.loginOverlay, loginOverlay && styles.open)} ref={this.overlay}>
                    <div className={classnames(authStyles.wrapper, styles.wrapper)}>
                        <LoginForm noAccount applyDiscount={false}/>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
