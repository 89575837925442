import TagManager from 'react-gtm-module';
import config from '../config.js';

const getDataLayer = (eventCategory, eventAction, eventLabel) => ({
    dataLayer: {
        event: 'gtmZoneEvent',
        eventCategory,
        eventAction,
        eventLabel,
        'eventNonInteraction': 'false'
    }
});

const getSendDataLayer = (hitType, page) => ({
    dataLayer: {
        event: { hitType, page }
    }
});

const TagEvents = {
    init: {
        gtmId: config.gtmId,
        dataLayer: {
            pageInstanceID: '',
            environment: 'production',
            environmentVersion: '1.0',
            page: {
                pageInfo: {
                    pageID: '/',
                    pageUrl: '/',
                    pageName: 'alternativa-iqos',
                    pageBrand: 'Iqos'
                },
                category: {
                    primaryCategory: 'alternativa-iqos',
                    subCategory1: '',
                    subCategory2: '',
                    pageType: ''
                },
                attributes: {
                    country: 'RO',
                    language: 'ro',
                    currency: 'RON'
                }
            },
            user: {
                affiliation: 'Anonymous',
            }
        }
    },

    howItWorks: getDataLayer('Clicks', 'Homepage_Quiz_IQOS', 'how_it_works'),
    startQuiz: (position) => getDataLayer('Clicks', 'Homepage_Quiz_IQOS', `start_quiz_position_${position}`),

    seeYourResult: getDataLayer('Clicks', 'Quiz_IQOS', 'see_your_result'),
    virtualPageView: (position) => getSendDataLayer('pageview', `quiz_question_${position}`),
    nextQuestion: (step_id) => getDataLayer('Clicks', 'Quiz_IQOS', `next_question_${step_id}`),
    orderNow: (position) => getDataLayer('Clicks', 'Quiz_IQOS', `order_now_${position}`),

    loginClick: getDataLayer('Clicks', 'Quiz_IQOS', 'quiz_login'),
    registerClick: getDataLayer('Clicks', 'Quiz_IQOS', 'quiz_register'),
    startRegistration: getDataLayer('Clicks', 'Quiz_IQOS', 'start_registration'),
    finishRegistration: getDataLayer('Clicks', 'Quiz_IQOS', 'finish_registration'),
};

export { TagManager, TagEvents };