import React from "react";
import { StyledCheckbox } from "./CheckboxStyle.js";
import "antd/dist/antd.css";
import styles from "./LoginInput.module.scss";

const CheckboxInput = ({ text, onChange, href, isFirst, info, checked }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom: "-18px",
      width: "100%",
    }}
  >
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        color: "white",
        margin: `${isFirst ? "40px 10px 10px" : "10px"}`,
      }}
    >
      {href ? (
        <a href={href} style={{ color: "#d6a76f" }}>
          {text}
        </a>
      ) : (
        text
      )}
    </StyledCheckbox>
    {info && (
      <small className={styles.info} style={{ marginLeft: "20px" }}>
        {info}
      </small>
    )}
  </div>
);
export default CheckboxInput;
