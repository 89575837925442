import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";

import "./styles/App.scss";
import React, { Component } from "react";
import Router from "./components/Routes/router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./stores/createStore";
import { Provider } from "react-redux";
import createStore from "./stores";
import config from "./config";
import moment from "moment";
import store from "store";
import { TagManager, TagEvents } from './utils/GTM';

const storeProvider = createStore(history);

export default class App extends Component {
  componentDidMount() {
    const expireDate = store.get(config.user.tokenExpireDate);

    if (
      expireDate &&
      new moment(expireDate).diff(new moment(), "seconds") <= 10
    ) {
      store.remove(config.user.tokenExpireDate);
      store.remove(config.user.token);
      store.remove(config.user.refreshToken);
      store.remove(config.user.expiresIn);
      store.remove(config.user.data);
      store.remove(config.auth.refreshInProgress);
      window.location = config.path.index;
    }

    TagManager.initialize(TagEvents.init);
  }

  render() {
    return (
      <Provider store={storeProvider}>
        <ConnectedRouter history={history}>
          <div className={"App"}>
            <Router />
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}
