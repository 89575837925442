import { createReducer } from 'reduxsauce';
import { ForgotPasswordTypes } from './Actions';

const INITIAL_STATE = {
    loading: false,
    errors: null,
    isSuccess: false
};

export const forgotPasswordLoading = (state) => ({
    ...state,
    loading: true,
    errors: null,
    isSuccess: false
});

export const forgotPasswordSuccess = (state) => ({
    ...state,
    loading: false,
    errors: null,
    isSuccess: true
});

export const forgotPasswordError = (state, {errors}) => ({
    ...state,
    loading: false,
    errors: errors,
    isSuccess: false
});

export const sendForgotPasswordRequest = (state, {email}) => ({
   ...state,
   loading: false,
   errors: null,
   email: email
});

export const reducer = createReducer(INITIAL_STATE, {
    [ForgotPasswordTypes.FORGOT_PASSWORD_LOADING]: forgotPasswordLoading,
    [ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
    [ForgotPasswordTypes.SEND_FORGOT_PASSWORD_REQUEST]: sendForgotPasswordRequest,
    [ForgotPasswordTypes.FORGOT_PASSWORD_ERROR]: forgotPasswordError
});
