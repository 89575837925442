import React from "react";
import styles from './Styles.module.scss';
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import Popup from "reactjs-popup";
import ManageCookies from "../../../../components/Footer/parts/ManageCookies";
import footerStyles from '../../../../components/Footer/Footer.module.scss';
import cookies from "../../../../utils/cookies";

class CookieDisclaimer extends React.Component {

  state = {
    showManageCookiesPopup: false,
    visible: true
  }

  toggleModal = () => {
    this.setState({
      showManageCookiesPopup: !this.state.showManageCookiesPopup
    });
  };

  componentDidMount() {
    if (cookies.read('cookies_accepted') == 1) {
      this.setState({ visible: false });
    } else {
      if (!(cookies.read('dw_Technical_cookie')) && !(cookies.read('dw_Advertisement_cookie'))) {
        cookies.create('dw_Technical_cookie', 'opt-in', 333);
        cookies.create('dw_Advertisement_cookie', 'opt-out', 333);
      }

      this.setState({ visible: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.showManageCookiesPopup !== this.state.showManageCookiesPopup && cookies.read('cookies_accepted') == 1) {
      this.setState({ visible: false });
    }
  }

  acceptCookies = () => {
    cookies.create('cookies_accepted', 1, 333);
    cookies.create('dw_Technical_cookie', 'opt-in', 333);
    cookies.create('dw_Advertisement_cookie', 'opt-in', 333);

    window.dataLayer.push({
      'event': 'cookieSettings',
      'cookieType': 'performance',
      'cookieValue': 'opt-in',
    });

    window.dataLayer.push({
      'event': 'cookieSettings',
      'cookieType': 'advertising',
      'cookieValue': 'opt-in',
    });

    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    return visible && (
      <>
      <div className={styles.cookie_disclaimer}>
        <div className={styles.container}><p>Folosim cookies pentru a-ți oferi o experiență personalizată de comunicare și pentru
          a-ți oferi publicitate relevantă, bazată atât pe comportamentul tău pe acest site, cât și
          pe altele, pentru optimizarea platformei, funcționarea corectă a acesteia și pentru a reține preferințele tale.
          Apasă <b>“Află mai multe”</b> pentru detalii sau pentru a-ți ajusta
          preferințele. Te poți răzgândi oricând apăsând butonul “Gestionează cookies.” <br/><br/> Datele personale
          colectate vor fi folosite conform <a className={styles.buton_conditii} href="https://www.iqos.ro/privacy" target="_blank">Notificării de protecție a datelor</a>.</p>
          <div className={styles.linksContainer}>
            <a href="#" className={styles.cookie_hint_settings} onClick={(e) => this.toggleModal(e)}>AFLĂ MAI MULTE</a>
            <a className={styles.accept_terms} onClick={this.acceptCookies}>ACCEPT TOATE</a>
          </div>
        </div>


      </div>
        <Popup modal closeOnDocumentClick
               className={'manageCookies'}
               open={this.state.showManageCookiesPopup}
               onClose={this.toggleModal}
               contentStyle={{width: "70%", height: "70%"}}
        >
          {close => (
            <>
              <img src="https://sandbox.gd.ro/images/esc.png" className={footerStyles.closePopup} onClick={close} onTouchStart={close} alt="close-popup"/>
              <ManageCookies onClose={close}/>
            </>
          )}
        </Popup>
        </>

    );

  }
}

export default CookieDisclaimer;
