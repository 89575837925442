import React from 'react';
import {connect} from 'react-redux';

// Assets
import styles from './AgeGate.module.scss';

// Sections
import {LinkContainer} from 'react-router-bootstrap';
import {Nav, Image} from 'react-bootstrap';
import config from '../../config';
import store from 'store';

class AgeGate extends React.Component {
	state = {
		userDecline: false,
		isPWA: store.get(config.user.isPWA)
	};

	handleAccept = () => {
		store.set(config.user.isAdult, true);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(
			{
				'event': 'gatewayLegalAge',
				'selectedLegalAgeBoolean': 'Yes',
				'eventCategory': 'Clicks',
				'eventAction': 'YES',
				'eventLabel': 'Age Gate',
				'eventValue': 0,
				'eventNonInteraction': true
			});
	};

	handleDecline = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(
			{
				'event': 'gatewayLegalAge',
				'selectedLegalAgeBoolean': 'No',
				'eventCategory': 'Clicks',
				'eventAction': 'NO',
				'eventLabel': 'Age Gate',
				'eventValue': 0,
				'eventNonInteraction': true
			});

		this.setState({userDecline: true});
	};

	goBack = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(
			{
				'event': 'gatewayLegalAge',
				'selectedLegalAgeBoolean': 'OK',
				'eventCategory': 'Clicks',
				'eventAction': 'OK',
				'eventLabel': 'Age Gate',
				'eventValue': 0,
				'eventNonInteraction': true
			});

		this.setState({userDecline: false});
	};

	render() {
		const {isPWA} = this.state;

		return (
			<div className={styles.ageGate}>
				<div className={styles.centerAlign}>
					<div className={styles.logo}></div>

					{
						this.state.userDecline ? (
							<div className={styles.secondStep}>
								<h2>Trebuie să ai peste 18 ani pentru a accesa acest website.</h2>
								<a href="#" onClick={this.goBack} className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}>Ok</a>
							</div>
						) : (
							<div className={styles.firstStep}>
								<h2>ACEST WEBSITE ESTE DESTINAT PERSOANELOR CU VÂRSTA PESTE 18 ANI, FUMĂTOARE ȘI REZIDENTE ÎN
									ROMÂNIA. DOREȘTI SĂ CONTINUI?</h2>

								<a href="" className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}
								   onClick={this.handleDecline}>
									Nu
								</a>

								<a href="" className={isPWA ? styles.primaryButtonPWA : styles.primaryButton}
								   onClick={this.handleAccept}>
									Da
								</a>

								<p>Centrul de Asistență Clienți – LiveChat pe <a href="https://www.iqos.ro/">iqos.ro</a>; zilnic, în intervalul
									8:00 – 22:00;<br/>Copyright 2015 - {new Date().getFullYear()} Philip Morris
									Trading S.R.L. Toate drepturile rezervate.</p>

								<p>
									<small>
										Bine ai venit pe IQOS.ro, un website operat de Philip Morris Trading S.R.L. Aici vei putea afla mai multe despre elementele și caracteristicile produsului IQOS și, de asemenea, vei putea cumpăra sistemul IQOS, componente ale sistemului IQOS și HEETS, pentru a fi utilizate exclusiv cu IQOS. Noi tratăm foarte atent modul în care publicul larg află despre produsele noastre și, de asemenea, modul în care acesta le achiziționează. Poți accesa acest site numai dacă ai peste 18 ani, ești fumător și rezident în România. Pentru a confirma faptul că ești fumător și ai vârsta de peste 18 ani, te rugăm să folosești butonul „DA”. În caz contrar, te rugăm să folosești butonul „NU”. În continuare, în interiorul site-ului, îţi pot fi solicitate informații suplimentare cu caracter personal, în vederea achiziționării IQOS, a produselor IQOS sau HEETS.
									</small>
								</p>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

export default connect(
)(AgeGate);
