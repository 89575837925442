import React from "react";
import store from "store";
import classnames from "classnames";

// Assets
import config from "../../config";

// Sections
import Header from "../Header";
import Footer from "../Footer";

class Layout extends React.Component{
	render() {
		const campaignEnd = true;
		return (
			campaignEnd ?
				<div className={classnames('campaign-end', this.props.class ? this.props.class : '')}>
					<h1>
						Campania s-a încheiat pe 31 august.
						Te așteptăm în continuare pe <a href="https://iqos.ro">IQOS.RO</a>.
					</h1>
				</div> :
				<>
					<Header/>

					<div className={classnames('content', this.props.class ? this.props.class : '')}>
						{this.props.children}
					</div>

					<Footer/>
				</>
		)
	}
}

export default Layout;
