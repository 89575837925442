import React, { useState } from "react";
import styles from "./Style.module.scss";
import LoginForm from "./LoginForm";
import Register from "./Register";
import { TagEvents, TagManager } from "../../../../../../utils/GTM";

const Auth = () => {
  const [hasAccount, setHasAccount] = useState(true);
  const goToRegister = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    TagManager.dataLayer(TagEvents.registerClick);
    TagManager.dataLayer(TagEvents.startRegistration);
    setHasAccount(false);
  };
  const goToLogin = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setHasAccount(true);
  };

  return (
    <div className={styles.wrapper}>
      {hasAccount ? (
        <LoginForm goToRegister={goToRegister} applyDiscount={true} />
      ) : (
        <Register goToLogin={goToLogin} />
      )}
    </div>
  );
};

export default Auth;
