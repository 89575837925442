import React from "react";
import styles from "./Style.module.scss";
import { Reveal } from "react-reveal";
import Button from "../../../../../../components/Button/Button";
import config from "../../../../../../config";

const Completed = () => {
  const goToRegulation = () => {
    window.open(config.path.regulation, '_blank');
  }

  return (
    <div className={styles.wrapper}>
      <Reveal effect="fadeInUp">
        <h1>
          Ai participat deja la acest quiz. <br /> Urmărește-ne pe{" "}
          <a href="https://iqos.ro/">iqos.ro</a> pentru mai multe surprize.
        </h1>

        <Button type={"secondary"} noLink onClick={goToRegulation}>
          Regulamentul campaniei
        </Button>
      </Reveal>
    </div>
  );
};

export default Completed;
