import store from "store";
import config from "../config";

const errorSwitch = (status) => {
    switch (status) {
        case 401:
            store.remove(config.user.tokenExpireDate);
            store.remove(config.user.token);
            store.remove(config.user.refreshToken);
            store.remove(config.user.expiresIn);
            store.remove(config.user.data);
            store.remove(config.auth.refreshInProgress);
            window.location = config.path.login;
            break;
        case 500:
            break;
        case 404:
            break;
        default:
            break;
    }
};

export default {
    errorSwitch
}
