import React from 'react';
import store from 'store';
import classnames from "classnames";
import {connect} from 'react-redux';

// Assets
import config from "../../config";
import cookies from "../../utils/cookies";
import styles from './Footer.module.scss';
import disclaimerStyles from "./parts/disclaimer.module.scss";

// Sections
import Popup from "reactjs-popup";
import {Nav} from "react-bootstrap";
import Collapsible from 'react-collapsible';
import {LinkContainer} from "react-router-bootstrap";
import ManageCookies from "./parts/ManageCookies";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

class Footer extends React.Component {
	state = {
		isLoggedIn: store.get(config.user.token),
		isPWA: store.get(config.user.isPWA),
		showManageCookiesPopup: false,
		showDisclaimer: false,
		isMobile: window.innerWidth < 1024,
		width: 0
	};

	componentDidMount() {
		window.addEventListener('resize', this.isMobileWidth);

		if(!(cookies.read('CookiesNotice'))) {
			this.setState({
				showDisclaimer: window.location.hostname === 'iqos.ro'
			});
		}
	}

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	isMobileWidth = () => {
		this.setState({ isMobile: window.innerWidth < 1210 });
	};

	toggleModal = () => {
		this.setState({
			showManageCookiesPopup: !this.state.showManageCookiesPopup
		});
	};

	handleAcceptTerms = (event) => {
		event.preventDefault();
		this.setState({ showDisclaimer: false });
	};

	render() {
		const {isPWA, isLoggedIn, showDisclaimer, isMobile} = this.state;

        return(
            <div className={isPWA ? styles.footerPWA : styles.footer}>
                <div className={isPWA ? styles.containerPWA : ''}>
                    <div className={styles.details}>
                        <a href="http://www.iqos.ro/" className={styles.logo} target="_blank" rel="noopener noreferrer">IQOS</a>
                        <p>&copy; {new Date().getFullYear()} Philip Morris Products SA. <br /> Toate drepturile rezervate.</p>
                    </div>

                    {isMobile === false ? (
                        <ul className={styles.columns}>
                            <li>
                                <ul>
                                    <li>Linkuri utile</li>
									<li><a href="https://www.iqos.ro/store/ro/search" target="_blank">Găsește IQOS</a></li>
									<li><a href="https://www.iqos.ro/noutati" target="_blank">Noutăți</a></li>
                                    <li><a href="https://www.pmi.com" target="_blank">Accesează PMI.com</a></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li>Asistență</li>

									<li><a href="https://www.iqos.ro/contact-iqos" target="_blank">Contactează-ne</a></li>
									<li><a href="https://www.iqos.ro/asistenta/iqos/3-duo" target="_blank">Tutoriale</a></li>
									<li><a href="https://www.iqos.ro/iqos-service-points" target="_blank">Înlocuire IQOS</a></li>
									<li><a href="https://www.iqos.ro/iqos-intrebari-frecvente" target="_blank">Întrebări frecvente</a></li>
                                </ul>
                            </li>

                            <li className={styles.legal}>
                                <ul>
                                    <li>Legal</li>

                                    <li><a href="http://www.anpc.gov.ro" target="_blank" rel="noopener noreferrer">ANPC</a></li>
                                    <li><a href="https://www.iqos.ro/conditii-de-utilizare">Termeni și Condiții</a></li>
                                    <li><a href="https://www.iqos.ro/privacy">Notificare de protecție a datelor</a></li>
                                    <li><a href="https://www.iqos.ro/cookies">Politică de cookies</a></li>
                                    <li><a href="#" className="cookie_hint_settings" onClick={(e) => this.toggleModal(e)}>Gestionează cookies</a></li>
                                </ul>
                            </li>

                            <li className={styles.alwaysActive}>
                                <ul className={styles.social_links}>
                                    <li>Urmărește-ne</li>
                                    <li>
                                        <a href="https://www.facebook.com/iqos.ro" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook-square" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/iqos_support_ro" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/iqos_ro/" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    ) : (
                        <div className={styles.footerDetails}>
                            <Collapsible
                                trigger="Linkuri utile"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="http://www.iqos.ro/" target="_blank" rel="noopener noreferrer">Mergi pe IQOS.ro</a>
								<LinkContainer to={config.path.home}>
									<Nav.Link eventKey="">
										Găsește IQOS
									</Nav.Link>
								</LinkContainer>
                                <a href="https://www.pmi.com" target="_blank" rel="noopener noreferrer">Accesează PMI.com</a>
                            </Collapsible>

                            <Collapsible
                                trigger="Asistență"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <LinkContainer to={config.path.contact}>
                                    <Nav.Link eventKey="">
                                        Contactează-ne
                                    </Nav.Link>
                                </LinkContainer>

															<a href={config.path.regulation} target="_blank">Regulament</a>

                            </Collapsible>

                            <Collapsible
                                trigger="Legal"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="http://www.anpc.gov.ro" target="_blank" rel="noopener noreferrer">ANPC</a>

                                <LinkContainer to={config.path.termsConditions}>
                                    <Nav.Link eventKey="">
                                        Termeni și Condiții
                                    </Nav.Link>
                                </LinkContainer>

								<LinkContainer to={config.path.userDataNotification}>
									<Nav.Link eventKey="">
										Notificare de protecție a datelor
									</Nav.Link>
								</LinkContainer>

								<LinkContainer to={config.path.cookiesPolicy}>
									<Nav.Link eventKey="">
										Politică de cookies
									</Nav.Link>
								</LinkContainer>

                                <a href="#" className="cookie_hint_settings" onClick={(e) => this.toggleModal(e)}>Gestionează cookies</a>
                            </Collapsible>

                            <Collapsible
                                trigger="Urmărește-ne"
                                className={styles.Collapsible}
                                triggerOpenedClassName={styles.CollapsibleTrigger}
                                contentOuterClassName={styles.outerWrapper}
                                contentInnerClassName={styles.innerWrapper}
                            >
                                <a href="https://www.facebook.com/iqos.ro" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-facebook" />
                                </a>
                                <a href="https://twitter.com/iqos_support_ro" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-twitter" />
                                </a>
                            </Collapsible>
                        </div>
                    )}
                </div>

				<Popup modal closeOnDocumentClick
					   className={'manageCookies'}
					   open={this.state.showManageCookiesPopup}
					   onClose={this.toggleModal}
					   contentStyle={{width: "70%", height: "70%"}}
				>
					{close => (
						<>
							<img src="https://sandbox.gd.ro/images/esc.png" className={styles.closePopup} onClick={close} alt="close-popup"/>
							<ManageCookies onClose={close}/>
						</>
					)}
				</Popup>

				{showDisclaimer && !isPWA &&
					<div className={disclaimerStyles.cookieDisclaimer}>
						<div className={disclaimerStyles.container}>
							<p>Apăsând pe butonul ”Accept”, ești de acord cu stocarea cookies proprii site-ului, cât și cu
								cele ale terților pe dispozitivul tău și ne ajuți să îți îmbunătățim experiența pe site, să
								analizăm performanța acestuia și să îți livrăm publicitate relevantă. Aici găsești <a
									className="buton-conditii" href="https://www.iqos.ro/cookies" target="_blank">Politica
									de cookies</a>.</p>
							<a href="#" className={disclaimerStyles.cookieHintSettings} onClick={(e) => this.toggleModal(e)}>GESTIONEAZĂ COOKIES</a>
							<a href="#" className={disclaimerStyles.saveCookies} onClick={(e) => this.handleAcceptTerms(e)}>Accept</a>
						</div>
					</div>
				}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Footer);
