import React, { useContext } from "react";
import styles from "./Style.module.scss";
import Button from "../../../../components/Button/Button";
import { Reveal } from "react-reveal";
import { AppContext } from "../../../../AppContext";
const steps = [
  {
    title: "Acceptă provocarea",
    content: "",
  },
  {
    title: "Răspunde întrebărilor.",
    content:
      "Răspunsul la prima întrebare îți aduce <span>10% reducere</span>, garantat. \n" +
      "În continuare, pentru fiecare răspuns corect, primești, în plus, încă <span>5% reducere</span>.\n",
  },
  {
    title: "Finalizează quiz-ul.",
    content: "Vezi rezultatul testului și află de ce reducere beneficiezi.",
  },
  {
    title: "Folosește reducerea pe <span>IQOS.RO</span> , în secțiunea ”SHOP”",
    content: "",
  },
];

const HowTo = () => {
  const { handleStartQuiz, ctaButton, authenticatedQuiz } = useContext(
    AppContext
  );

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Reveal effect="fadeInUp" cascade>
          <h2>Ce trebuie să faci?</h2>

          <div className={styles.listSteps}>
            {steps.map((step, key) => (
              <div className={styles.step} key={key}>
                <span
                  dangerouslySetInnerHTML={{ __html: key + 1 }}
                  className={styles.stepId}
                />
                <h3
                  dangerouslySetInnerHTML={{ __html: step.title }}
                  className={styles.title}
                />
                {step.content && (
                  <p
                    dangerouslySetInnerHTML={{ __html: step.content }}
                    className={styles.description}
                  />
                )}
              </div>
            ))}
          </div>

          <Button
            type="primary"
            noLink
            style={authenticatedQuiz ? { display: "none" } : {}}
            onClick={() => handleStartQuiz(2)}
          >
            {ctaButton()}
          </Button>
        </Reveal>
      </div>
    </div>
  );
};

export default HowTo;
