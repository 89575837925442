import React from 'react';
import {default as ReactLoader} from "react-loader-spinner";

class Loader extends React.Component{
	render() {
		const {fullscreen} = this.props;
		return(
			<div style={{
				width: '100%',
				marginTop: fullscreen ? 'calc(50vh - 250px)' : '5vh',
				marginBottom: fullscreen ? 'calc(50vh - 250px)' : '5vh',
				borderRadius: '25px',
				textAlign: 'center'
			}}>
				<ReactLoader
					type="MutatingDots"
					color="#d6a76f"
					secondaryColor="#d6a76f"
					height={100}
					width={100}
				/>
			</div>
		)
	}
}

export default Loader;
