import React, { useContext } from "react";
import styles from "./Styles.module.scss";
import image1 from "./images/benefits-img1.png";
import image2 from "./images/benefits-img2.png";
import image3 from "./images/benefits-img3.png";
import { Reveal } from "react-reveal";
import Button from "../../../../components/Button/Button";
import { AppContext } from "../../../../AppContext";

const benefits = [
  {
    title: "Fără fum.",
    slug: "fara-fum",
    image: image1,
    description:
      "Utilizatorii IQOS care renunță la țigările obișnuite menționează, cel mai des, faptul că\n" +
      "<b>hainele, mâinile și părul lor nu mai miros a fum de țigară</b>.",
  },
  {
    title: "Timp cu cei dragi.",
    slug: "timp-cu-cei-dragi",
    image: image2,
    description:
      "Petreci mai mult timp cu prietenii, fără să-i deranjezi cu fumul de țigară.",
  },
  {
    title: "Satisfacția tutunului încălzit.",
    slug: "",
    image: image3,
    description: "Fără ardere, fără respirație cu miros de țigări.",
  },
];

const ListBenefits = () => {
  const { handleStartQuiz, ctaButton, authenticatedQuiz } = useContext(
    AppContext
  );

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Reveal effect={"fadeInUp"}>
          <h2>Descoperă beneficiile IQOS</h2>
          <p>
            IQOS schimbă regulile fumatului tradițional, folosind tehnologia
            Heat-not-Burn: <b>fără fum, fără scrum, mai puțin miros</b>.
          </p>
        </Reveal>

        <div className={styles.listBenefits}>
          {benefits.map((benefit, key) => (
            <div className={styles.benefit} key={key}>
              <Reveal effect={key % 2 === 0 ? "fadeInRight" : "fadeInLeft"}>
                <img src={benefit.image} alt={benefit.slug} />
              </Reveal>

              <Reveal effect={key % 2 === 0 ? "fadeInLeft" : "fadeInRight"}>
                <div className={styles.details}>
                  <h3 dangerouslySetInnerHTML={{ __html: benefit.title }} />
                  <p
                    dangerouslySetInnerHTML={{ __html: benefit.description }}
                  />
                </div>
              </Reveal>
            </div>
          ))}
        </div>

        <Reveal effect={"fadeInUp"}>
          <Button
            type={"primary"}
            style={authenticatedQuiz ? { display: "none" } : {}}
            noLink
            onClick={() => handleStartQuiz(3)}
          >
            {ctaButton()}
          </Button>
        </Reveal>
      </div>
    </div>
  );
};

export default ListBenefits;
