import React from "react";
import { Reveal } from "react-reveal";
import classnames from "classnames";
import styles from "./Style.module.scss";

const Discount = ({ discount, step }) => (
  <Reveal effect="fadeInRight">
    <div className={styles.formDetails}>
      <div className={styles.steps}>
        {[1, 2, 3].map((bullet) => (
          <>
            {step === bullet && (
              <div className={classnames(styles.step, styles.current)}>
                {step}
              </div>
            )}

            {step < bullet && (
              <div className={styles.step}>
                <i className="fa fa-lock" />
              </div>
            )}
            {step > bullet && (
              <div className={styles.step}>
                <i className="fa fa-check" />
              </div>
            )}
            {bullet !== 3 && <div className={styles.divider} />}
          </>
        ))}
      </div>

      <h2>
        Discountul tău <br />
        de până acum:
      </h2>

      <span className={styles.currentDiscount}>
        <b>
          {discount} <div className={styles.icon}>%</div>
        </b>
        <small>reducere</small>
      </span>
    </div>
  </Reveal>
);

export default Discount;
