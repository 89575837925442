const PROXY_PATH = window.location.hostname === 'campanie-iqos-quiz.gd.ro' ? '' : '/alternativa-iqos';
export default {
  apiUrl:
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
      ? "http://127.0.0.1:3000/api/"
      : (window.location.hostname === "www.iqos.ro" ? "https://www.iqos.ro/alternativa-iqos/api/" : "https://prod-campanie-iqos-quiz.gd.ro/api/"),
  cookieConfig:
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
      ? ";domain=localhost;path=/"
      : `;secure;domain=iqos.ro;path=/`,
  user: {
    token: "token",
    tokenExpireDate: "tokenExpireDate",
    refreshToken: "refreshToken",
    exploreMode: "exploreMode",
    disableExploreModePopup: "disableExploreModePopup",
    expiresIn: "expiresIn",
    isAdult: "isAdult",
    isPWA: "isPWA",
    data: "data",
    bottomShortcuts: "bottomShortcuts",
    menuOpenings: "menuOpenings",
    orders: "orders",
    points: "points",
    pushNotificationsToken: "pushNotificationsToken",
  },
  path: {
    index: `${PROXY_PATH}/`,
    login: `${PROXY_PATH}/login`,
    home: `${PROXY_PATH}/home`,
    forgotPassword: `${PROXY_PATH}/recuperare-parola`,
    termsConditions: `${PROXY_PATH}/termeni-si-conditii`,
    userDataNotification: `${PROXY_PATH}/notificare-protectia-datelor`,
    FAQs: `${PROXY_PATH}/intrebari-frecvente`,
    benefits: `${PROXY_PATH}/beneficii`,
    referral: `${PROXY_PATH}/recomanda`,
    map: `${PROXY_PATH}/harta`,
    referralList: `${PROXY_PATH}/test`,
    offlineRedemption: `${PROXY_PATH}/test`,
    contact: `${PROXY_PATH}/contact`,
    notFound: `${PROXY_PATH}/page-not-found`,
    regulation: `${PROXY_PATH}/regulamentul-campaniei`,

    cookiesPolicy: `https://www.iqos.ro/cookies`,
    whatIsIQOS: "https://www.iqos.ro/produse/ce-este-iqos",
    tryIQOS: "https://www.iqos.ro/incearca-iqos",
    shop: "https://www.iqos.ro/iqos3-duo",
    support: "https://www.iqos.ro/asistenta",
    mentholBan: "https://www.iqos.ro/alegefresh/",
    community: "https://www.iqos.ro/comunitate-iqos",
    iqosClub: "https://www.iqos.ro/iqos3-duo?loyalty=1",
    myIqos: "https://www.iqos.ro/my-iqos"
  },
  activities: {},
  dateFormat: {
    projects: "MMM D, YYYY",
  },
  auth: {
    refreshInProgress: "refreshInProgress",
  },
  googleMapsKey: "AIzaSyDLcdVvp_yV0i0V3-WWHZ2AY2BhTHESMYk",
  gtmId: 'GTM-PLBRD26',
  proxyPassPath: PROXY_PATH
};
