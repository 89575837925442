import React from 'react';
import image from './assets/image.png';
import styles from './Styles.module.scss';
import {Reveal} from "react-reveal";

const HowItWorks = () => (
    <div className={styles.section}>
        <div className={styles.container}>
            <Reveal effect={'fadeInLeft'}>
                <img src={image} alt="cum-functioneaza-campania"/>
            </Reveal>

            <Reveal effect={'fadeInRight'}>
                <div className={styles.details}>
                    <h2>Cum funcționează campania?</h2>
                    <p>Răspunde la <b>3 întrebări</b> și poți obține până la <b>20% reducere</b> pentru un <b>Starter Kit IQOS 2.4+ / Starter Kit IQOS 3 DUO</b>, dacă ești utilizator nou, sau pentru un <b>Loyalty Kit IQOS 2.4+ / Loyalty Kit IQOS 3 DUO</b>, dacă ești deja utilizator IQOS și ai achiziționat un Starter Kit în prealabil.</p>
                    <p>Fiecare răspuns corect îți aduce <b>o reducere mai mare</b>.</p>
                    <p>Reducerea acumulată se aplică la final, după ce adaugi dispozitivul dorit în coșul de cumpărături.</p>
                </div>
            </Reveal>
        </div>
    </div>
);

export default HowItWorks;