import { combineReducers } from 'redux';
import configureStore from './createStore';
import rootSaga from './rootSaga';
import { connectRouter } from 'connected-react-router';
import { reducer as login } from './Login/Reducers';
import { reducer as logout } from './Logout/Reducers';
import { reducer as forgotPassword } from './ForgotPassword/Reducers';

export default (history) => {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        login,
        logout,
        forgotPassword
    });

    return configureStore(rootReducer, rootSaga);
};
