import React, { useState } from "react";
import { Image } from "react-bootstrap";
import styles from "./LoginInput.module.scss";

const SelectInput = ({ name, label, onChange, icon, arr, disabled, info, value }) => {
  const [focused, setFocused] = useState(false);
  const getFormStyle = () => {
    const isFocused = focused ? styles.activeFormContainer : "";
    return [
      styles.formContainer,
      styles.first,
      styles.isSelect,
      isFocused,
    ].join(" ");
  };

  return (
    <div className={getFormStyle()}>
      <Image className={styles.icon} src={icon} />
      <div className={styles.field}>
        <select
          onChange={onChange}
          className={styles.input}
          placeholder={label}
          disabled={disabled}
          name={name}
          required
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
        >
          <option value="" selected disabled hidden>
            {label}
          </option>
          {arr.map(({id, name}) => (
            <option value={id}>{name}</option>
          ))}
        </select>
      </div>
      {info && <small className={styles.info}> {info} </small>}

    </div>
  );
};

export default SelectInput;
