import React from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ro_RO";
import "moment/locale/ro";
import styles from "./LoginInput.module.scss";

const disabledDate = (current) => current && current.valueOf() > Date.now();

const Datepicker = ({ label, info, ...rest }) => (
  <div
    style={{ display: "flex", flexDirection: "column", marginBottom: "-18px" }}
  >
    <DatePicker
      format={["DD/MM/YYYY", "DD/MM/YYYY"]}
      locale={locale}
      placeholder={label}
      showToday={false}
      disabledDate={disabledDate}
      {...rest}
    />
    {info && (
      <small
        style={{
          display: "flex",
        }}
      >
        {info && <small className={styles.info}> {info} </small>}
      </small>
    )}
  </div>
);
export default Datepicker;
