import { all } from 'redux-saga/effects';
import loginSaga from './Login/Saga';
import logoutSaga from './Logout/Saga';
import forgotPasswordSaga from './ForgotPassword/Saga';

export default function* root() {
    yield all([
        loginSaga(),
        logoutSaga(),
        forgotPasswordSaga()
    ]);
}
