import React from "react";
import { Radio } from "antd";
import styles from "./LoginInput.module.scss";

const RadioMultiple = ({ arr = [], onChange, text, info, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "-18px",
      }}
    >
      <div
        className="radioWrapper"
        style={{
          maxWidth: "311px",
          display: "flex",
          color: "rgba(255,255,255,.5)",
          fontSize: "16px",
          margin: "10px 0 0",
          height: "50px",
          alignItems: "flex-end",
          justifyContent: "space-around",
        }}
      >
        <div>{text}</div>
        <Radio.Group onChange={onChange} value={value}>
          {arr.map((option, i) => (
            <Radio value={option}> {option}</Radio>
          ))}
        </Radio.Group>
      </div>
      {info && <small className={styles.info}> {info} </small>}
    </div>
  );
};

export default RadioMultiple;
