import React from "react";
import store from "store";

import {slide as Drawer} from "react-burger-menu";
import MenuIcon from "@material-ui/icons/Menu";

import MenuList from "./MenuList";

import styles from "./Menu.scss";
import config from "../../../config";
import {connect} from "react-redux";

class Menu extends React.Component {
    state = {
        isMobile: window.innerWidth < 1210,
        isOpen: false
    };

    componentDidMount() {
        const isLoggedIn = store.get(config.user.token);

        if (isLoggedIn) {
            const userData = store.get(config.user.data);
        }

        window.addEventListener('resize', this.isMobileWidth);
    }

    isMobileWidth = () => {
        this.setState({ isMobile: window.innerWidth < 1210 });
    };

    render() {
        const {isPWA, isMobile, isOpen} = this.state;

        return (
            <div>
                {isMobile ? (
                    <Drawer
                        burgerButtonClassName={'menuIcon'}
                        customBurgerIcon={<MenuIcon/>}
                        isOpen={isOpen}
                        styles={styles}
                    >
                        <ul className={styles.menu}>
                            <MenuList/>
                        </ul>
                    </Drawer>
                ) : (
                    <ul className="menu">
                        <MenuList/>
                    </ul>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(
    mapStateToProps,
    null
)(Menu);
