import React, { useEffect, useState, useContext } from "react";
import styles from "./Style.module.scss";
import Button from "../../../../../../components/Button/Button";
import { Reveal } from "react-reveal";
import config from "../../../../../../config";

import { AppContext } from "../../../../../../AppContext";
import { getQuestions, answerQuestion } from "../../../../../../AppRequests";
import Discount from "./Discount";
import Loader from "../../../../../../components/Loader";
import { TagEvents, TagManager } from "../../../../../../utils/GTM";

const Quiz = () => {
  const {
    questions,
    setQuestions,
    discount,
    setDiscount,
    setFinishedQuiz,
    setResults,
    setStep,
    step,
    setLoading,
    loadingQuestions,
    setLoadingQuestions,
  } = useContext(AppContext);

  const [answer, setAnswer] = useState(null);

  const getAllQuestions = async () => {
    setLoadingQuestions(true);
    const { data } = await getQuestions();
    setDiscount(data.totalDiscount);
    setQuestions(data.questions);
    setFinishedQuiz(data.finishedQuiz);
    setResults(data.results);
    if (data.questions.length) {
      setStep(data.questions[0].num);
    }
    setAnswer(null);
    setLoadingQuestions(false);
  };
  useEffect(() => {
    goToMentholBan();
    getAllQuestions();
  }, [step]);

  const { title, choices = [], id } = questions.length ? questions[0] : {};

  const submitAnswer = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(true);
    await answerQuestion(id, answer);

    if (step < 3) {
      TagManager.dataLayer(TagEvents.nextQuestion(step));
      TagManager.dataLayer(TagEvents.virtualPageView(step + 1));
    }
    if (step === 3) TagManager.dataLayer(TagEvents.seeYourResult);

    await getAllQuestions();
    setLoading(false);
  };

  const goToRegulation = () => {
    window.open(config.path.regulation, '_blank');
  }

  const goToMentholBan = () => {
    const learnMoreButton = document.getElementById('seeMore');
    if (learnMoreButton) {
      learnMoreButton.onclick = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      };
    }
  }

  const isAnswerChosen = answer === null ? { opacity: ".5" } : {};
  return (
    <div className={styles.wrapper}>
      {loadingQuestions ? (
        <Loader />
      ) : (
        <Reveal effect="fadeInLeft">
          <div className={styles.form}>
            <div className={styles.formStep}>
              Întrebarea{" "}
              <span>
                <b>{step}</b> / 3
              </span>
            </div>

            <h1 className={styles.question} dangerouslySetInnerHTML={{ __html: title }} onClick={goToMentholBan}/>

            <div className={styles.answers}>
              {choices.map(({ id, text }) => {
                const selectedAnswer = answer && answer === id ? "active" : "";
                return (
                  <label
                    key={id}
                    className={`control control--radio ${selectedAnswer}`}
                    onClick={() => setAnswer(id)}
                  >
                    <p>{text}</p>

                    <input value={id} type="radio" />

                    <div className="control__indicator" />
                  </label>
                );
              })}
            </div>

            <Button
              disabled={answer === null}
              type="primary"
              noLink
              onClick={submitAnswer}
              style={isAnswerChosen}
            >
              {step < 3 ? "Următoarea întrebare" : "Vezi rezultatul"}
            </Button>
            <Button type={"secondary"} noLink onClick={goToRegulation}>
              Citește regulamentul promoției
            </Button>
          </div>
        </Reveal>
      )}

      <Discount discount={discount} step={step} />
    </div>
  );
};

export default Quiz;
