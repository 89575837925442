import React, {useContext, useState} from "react";
import styles from "./Style.module.scss";
import Button from "../../../../../../components/Button/Button";
import {Reveal} from "react-reveal";
import Auth from "../Auth";
import Loader from "../../../../../../components/Loader";

import {AppContext} from "../../../../../../AppContext";
import {TagEvents, TagManager} from "../../../../../../utils/GTM";
import cookies from "../../../../../../utils/cookies";
import {submitDcsIdLogin} from "../../../../../../AppRequests";
import config from "../../../../../../config";
import Completed from "../Completed";

const Success = () => {
  const {results, discount, loadingQuestions, setLoadingQuestions, isAuth, setIsAuth} = useContext(
    AppContext
  );
  let text;
  if (results[1] && results[2] && results[3]) {
    text = `Felicitări! Ai răspuns corect la toate întrebările și ai obținut `;
  } else if (results[1] && results[2]) {
    text = `Felicitări! Ai răspuns corect la prima și a doua întrebare și ai obținut `;
  } else if (results[1] && results[3]) {
    text = `Felicitări! Ai răspuns corect la prima și a treia întrebare și ai obținut `;
  } else {
    text = `Felicitări! Ai răspuns corect la prima întrebare și ai obținut `;
  }

  const [isAlreadyCompleted, setIsAlreadyCompleted] = useState(false);

  const goToAuth = async () => {
    const cookieDcsId = cookies.read('consumer_dcs_id');
    const dcsId = parseInt(cookieDcsId) !== 0 ? cookieDcsId : null;
    if (dcsId) {
      setLoadingQuestions(true);
      const data = await submitDcsIdLogin(dcsId);
      const {status} = data;

      if (status === 'success') {
        const {
          data: {link},
        } = data;
        window.location.replace(link);
      } else {
        setLoadingQuestions(false);
        setIsAlreadyCompleted(true);
      }
    } else {
      window.scrollTo({top: 0, behavior: "smooth"});
      TagManager.dataLayer(TagEvents.orderNow(1));
      setIsAuth(true);
    }
  };

  const goToRegulation = () => {
    window.open(config.path.regulation, '_blank');
  };

  if (loadingQuestions) {
    return <Loader/>;
  } else if (isAlreadyCompleted) {
    return <Completed/>;
  }
  return (
    <>
      {isAuth ? (
        <Auth/>
      ) : (
        <div className={styles.wrapper}>
          <Reveal effect="fadeInUp">
            <div className={styles.steps}>
              <div className={styles.step}>
                <i className="fa fa-check"/>
              </div>
              <div className={styles.divider}/>
              <div className={styles.step}>
                <i className="fa fa-check"/>
              </div>
              <div className={styles.divider}/>
              <div className={styles.step}>
                <i className="fa fa-check"/>
              </div>
            </div>

            <h1>Felicitări!</h1>
            <p>
              {text}
              <b>{discount}% reducere</b>!
            </p>

            <span className={styles.currentDiscount}>
              <b>
                {discount}
                <div className={styles.icon}>%</div>
              </b>
              <small>reducere</small>
            </span>

            <p>
              Intră în secțiunea "<span>SHOP</span>" și achiziționează, la preț redus, un <b>Starter Kit IQOS 2.4+ / Starter Kit IQOS 3
              DUO</b>, dacă ești utilizator nou, sau un <b>Loyalty Kit IQOS 2.4+ / Loyalty Kit IQOS 3 DUO</b>, dacă ești utilizator
              IQOS și ai achiziționat un Starter Kit în prealabil. Discount-ul se va aplica în momentul în care produsul
              va fi adăugat în coș.
            </p>

            <Button type={"primary"} noLink onClick={goToAuth}>
              Cumpără acum
            </Button>

            <Button type={"secondary"} noLink onClick={goToRegulation}>
              Citește regulamentul promoției
            </Button>
          </Reveal>
        </div>
      )}
    </>
  );
};

export default Success;
