import React from 'react';
import styles from './Regulation.module.scss';
import Layout from "../../components/Layout";

const Regulation = () => (
    <Layout>
        <div className={'page_heading'}>
            <h1>Regulamentul campaniei</h1>
        </div>

        <div className="container">
            <p>Această promoție se aplică persoanelor cu vârsta peste 18 ani, fumătoare și rezidente în România.</p>
            <p>Promoția se aplică produselor din categoriile Starter Kit IQOS 3 DUO, Starter Kit IQOS 2.4+, Loyalty Kit IQOS
                3 DUO, Loyalty Kit IQOS 2.4+ o singură dată per utilizator care accesează această promoție, în limita stocului disponibil, iar reducerile
                se aplică conform pașilor de mai jos.
                Pentru a beneficia de promoție, fiecare utilizator va trebui să raspundă la 3 întrebări; fiecare răspuns
                corect acordat la fiecare dintre cele 3 întrebări reprezintă o reducere, astfel:</p>

            <h3>IQOS 3 DUO</h3>
            <ul>
                <li>Preț întreg Starter Kit IQOS 3 DUO / Loyalty Kit IQOS 3 DUO: <b>449 RON</b></li>
                <li>
                    Răspunsul corect la prima întrebare oferă o reducere adițională de 10% din prețul întreg de Starter Kit IQOS 3 DUO sau Loyalty Kit IQOS 3 DUO. Preț cu reducere 10%: <b>404 RON</b>
                </li>
                <li>
                    Răspunsul corect la cea de-a doua întrebare oferă o reducere adițională de 5% din prețul întreg de Starter Kit IQOS 3 DUO sau Loyalty Kit IQOS 3 DUO. Preț cu reducere 15%: <b>382 RON</b>
                </li>
                <li>
                    Răspunsul corect la cea de-a treia întrebare oferă o reducere adițională de 5% din prețul întreg de Starter Kit IQOS 3 DUO sau Loyalty Kit IQOS 3 DUO. Preț cu reducere 20%: <b>359 RON</b>
                </li>
            </ul>

            <h3>IQOS 2.4+</h3>
            <ul>
                <li>Preț întreg de Starter Kit IQOS 2.4+ sau Loyalty Kit IQOS 2.4+: <b>149 RON</b></li>
                <li>
                    Răspunsul corect la prima întrebare oferă o reducere de 10% din prețul întreg de Starter Kit 2.4+ sau Loyalty Kit IQOS 2.4+. Preț cu reducere 10%: <b>134 RON</b>
                </li>
                <li>
                    Răspunsul corect la cea de-a doua întrebare oferă o reducere adițională de 5% din prețul întreg de Starter Kit 2.4+ sau Loyalty Kit IQOS 2.4+. Preț cu reducere 15%: <b>127 RON</b>
                </li>
                <li>
                    Răspunsul corect la cea de-a treia întrebare oferă o reducere adițională de 5% din prețul întreg de Starter Kit 2.4+ sau Loyalty Kit IQOS 2.4+. Preț cu reducere 20%: <b>119 RON</b>
                </li>
            </ul>

            <p>În cazul în care un utilizator oferă un răspuns greșit la una dintre întrebări, reducerea adițională aferentă
                acelei întrebări nu va fi oferită.</p>
            <p>Un utilizator poate beneficia de această promoție o singură dată (poate completa cele 3 întrebări o singură
                dată), iar reducerea obținută se aplică <b>pentru achiziția unui singur produs</b> din categoriile Starter Kit IQOS
                3 DUO, Starter Kit IQOS 2.4+, Loyalty Kit IQOS 3 DUO, Loyalty Kit IQOS 2.4+.</p>
            <p>
                Dacă un utilizator existent IQOS a beneficiat deja de achiziția unei oferte de tip Starter Kit, acesta va putea achiziționa,
                la preț redus, pe baza discount-ului obținut prin parcurgerea celor trei întrebări, doar o ofertă de tip Loyalty Kit.
                Mai multe detalii <a href="https://www.iqos.ro/termeni-conditii-iqos-starter-kits-loyalty-kits" target="_blank">aici</a>.
            </p>
            <p>
                Discount-ul obținut la finalul celor trei întrebări se aplică exclusiv pentru un produs de tipul Starter Kit / Loyalty Kit din comandă.
            </p>
            <p>Promoția se desfășoară în perioada 01.07.2020 – 31.08.2020, în limita stocului disponibil.</p>

            <p><b>Organizatorul activităților promoționale detaliate mai sus este Mediapost Hit Mail S.A.</b></p>
            <p>Centrul de Asistență Clienți IQOS este disponibil de luni până duminică, între 08:00 și 22:00, la numărul
            0800030333, pentru orice întrebare.</p>
        </div>
    </Layout>
);

export default Regulation;