import axios from "axios";
import config from "./config";

/* Questions */
const axiosRequest = axios.create({
  headers: { Pragma: 'no-cache'},
  baseURL: config.apiUrl,
  withCredentials: true,
});

export const getStatus = async (dcsId) => {
  const res = await axiosRequest({
    url: "v1/quiz/status",
    method: "get",
    params: {
      dcsId
    }
  });
  return res.data;
};

export const getQuestions = async () => {
  const res = await axiosRequest({
    method: "get",
    url: "v1/questions",
  });
  return res.data;
};

export const answerQuestion = async (id, choiceId) => {
  const res = await axiosRequest({
    method: "post",
    url: `v1/questions/${id}/answer`,
    data: {
      choice: choiceId,
    },
  });
  return res.data;
};
/* Auth */

export const submitLogin = async (email, password, applyDiscount) => {
  try {
    const res = await axiosRequest({
      method: "post",
      url: `v1/auth/login`,
      data: {
        email,
        password,
        applyDiscount
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const submitDcsIdLogin = async (dcsId) => {
  try {
    const res = await axiosRequest({
      method: "post",
      url: `v1/auth/login-by-id`,
      data: {
        dcsId
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const submitRegister = async (userData) => {
  try {
    const res = await axiosRequest({
      method: "post",
      url: `v1/auth/register`,
      data: userData,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

/* Localities */
export const getCounties = async () => {
  try {
    const res = await axiosRequest({
      method: "get",
      url: `v1/localities/counties`,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getCities = async (county) => {
  try {
    const res = await axiosRequest({
      method: "get",
      url: `v1/localities/cities?county=${county}`,
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
