import React, { useContext, useEffect } from "react";
import styles from "./Style.module.scss";
import Intro from "./components/Intro";
import Quiz from "./components/Quiz";
import Success from "./components/Success";
import Completed from "./components/Completed";
import { AppContext } from "../../../../AppContext";

const QuizWrapper = () => {
  const { startedQuiz, finishedQuiz, authenticatedQuiz } = useContext(
    AppContext
  );

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {authenticatedQuiz ? (
          <Completed />
        ) : (
          <>
            {!startedQuiz && !finishedQuiz && <Intro />}
            {startedQuiz && !finishedQuiz && <Quiz />}
            {startedQuiz && finishedQuiz && <Success />}
          </>
        )}
      </div>
    </div>
  );
};

export default QuizWrapper;
