import React, { useState } from "react";
import styles from "./Style.module.scss";
import Button from "../../../../../../components/Button/Button";
import { Reveal } from "react-reveal";
import Alert from "react-bootstrap/Alert";
import LoginInput from "../../../../../../components/AuthForm";
import EyeIcon from "../../../../../../assets/images/icons/material-eye.svg";
import AuthButton from "../../../../../../components/AuthButton";
import { submitLogin } from "../../../../../../AppRequests";
import { TagEvents, TagManager } from "../../../../../../utils/GTM";
import Loader from "../../../../../../components/Loader";

const LoginForm = ({ goToRegister, noAccount, applyDiscount }) => {
  const [errors, setErrors] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [user, setUser] = useState({
    email: undefined,
    password: undefined,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (key) => (e) => {
    setUser({ ...user, [key]: e.target.value ? e.target.value : e.target });
  };

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(true);
    const { email, password } = user;
    const data = await submitLogin(email, password, applyDiscount);
    const { status, errors } = data;

    TagManager.dataLayer(TagEvents.loginClick);

    switch (status) {
      case "success":
        const {
          data: { link },
        } = data;
        window.location.replace(link);
        break;
      case "error":
        const errorMessage = errors[0].message;
        setErrors(errorMessage);
        setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Reveal effect="fadeInUp">
        <h1>Login în contul tău</h1>

        <form>
          <LoginInput
            onChange={handleChange("email")}
            name="email"
            label="Adresa de e-mail"
            type="email"
            first
          />

          <LoginInput
            onChange={handleChange("password")}
            label="Parola"
            name="password"
            type="password"
            secondaryIcon={EyeIcon}
            last
          />

          <AuthButton onClick={handleSubmit}>Conectează-te</AuthButton>

          {errors === "Utilizator inactiv" ? (
              <Alert variant="danger">
                <div>Contul încă nu este activat - accesează adresa de email specificată de tine în formularul de
                  înregistrare și activează-ți contul folosind link-ul de activare trimis anterior. În cazul în care nu ai
                  primit emailul de activare a contului, te rugăm să iei legătura cu noi, accesând pagina <a
                    href="https://www.iqos.ro/contact-iqos/" target="_blank">Contact</a>.
                </div>
              </Alert>
            ) :

            (errors ? (
              <Alert variant="danger">
                <div>{errors}</div>
              </Alert>
            ) : null)}



          {!noAccount &&
            <>
              <div className={styles.divider} />
              <h3 style={{ color: "#d6a76f" }}>Creează-ți cont</h3>

              <Button type={"primary"} noLink onClick={goToRegister}>
                Înregistrează-te
              </Button>
            </>
          }
        </form>
      </Reveal>
    </>
  );
};

export default LoginForm;
